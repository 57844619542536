@use '@angular/material' as mat;

@import '~@fontsource/open-sans/400.css';
@import '~@fontsource/open-sans/700.css';
@import '~@fontsource/fira-sans/400.css';
@import '~@fontsource/fira-sans/700.css';
@import '~@fontsource/archivo/400.css';
@import '~@fontsource/archivo/700.css';
@import '~@fontsource/lato/400.css';
@import '~@fontsource/lato/700.css';
@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/700.css';
@import '@fontsource/material-icons';

@include mat.core();
@include mat.legacy-core();

:root {
    // main colors
    --color-prime: #5daf30;
    --color-accent: #2c8344;
    --color-light: #d9f0d4;
    --color-bodyBg: #f9f9f9;
    --color-light-grey: #f3f3f3;
    --color-mid-grey: #6a7277;
    --color-dark-grey: #3e4747;

    // functional colors
    --color-success: #5ebd3e;
    --color-success-light: #e7f5e2;
    --color-warn: #ffb900;
    --color-warn-light: #fff5d9;
    --color-err: #ff3838;
    --color-err-light: #fbe1e1;

    // font sizes
    --font-normal: 14px;
    --font-bigger: 16px;
    --font-smaller: 12px;
}

// Material Color Tool https://material.io/resources/color/#!/?view.left=0&view.right=0
// Material Palette Generator http://mcg.mbitson.com/#!?mcgpalette0=%235daf30

$primary: (
    50: #ecf5e6,
    100: #cee7c1,
    200: #aed798,
    300: #8ec76e,
    400: #75bb4f,
    500: #5daf30,
    600: #55a82b,
    700: #4b9f24,
    800: #41961e,
    900: #308613,
    A100: #c9ffba,
    A200: #a1ff87,
    A400: #79ff54,
    A700: #65ff3b,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$accent: (
    50: #e6f0e9,
    100: #c0dac7,
    200: #96c1a2,
    300: #6ba87c,
    400: #4c9660,
    500: #2c8344,
    600: #277b3e,
    700: #217035,
    800: #1b662d,
    900: #10531f,
    A100: #8bffa1,
    A200: #58ff77,
    A400: #25ff4d,
    A700: #0cff38,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$warn: (
    50: #ffe7e7,
    100: #ffc3c3,
    200: #ff9c9c,
    300: #ff7474,
    400: #ff5656,
    500: #ff3838,
    600: #ff3232,
    700: #ff2b2b,
    800: #ff2424,
    900: #ff1717,
    A100: #ffffff,
    A200: #fffcfc,
    A400: #ffc9c9,
    A700: #ffb0b0,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$primary: mat.define-palette($primary);
$accent: mat.define-palette($accent);
$warn: mat.define-palette($warn);
$bodyBg: #f9f9f9;

$general-typography: mat.define-typography-config(
    $font-family: 'Uni Neue, sans-serif',
    $headline-1: mat.define-typography-level(24px, 32px, 500),
    $headline-2: mat.define-typography-level(16px, 22px, 700),
    $body-1: mat.define-typography-level(16px, 16px, 500),
    $body-2: mat.define-typography-level(14px, 14px, 500),
    $caption: mat.define-typography-level(12px, 12px, 500, null, 0.05em),
    $button: mat.define-typography-level(14px, 14px, 700, null, 0.15em),
);

$my-theme: mat.define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
            warn: $warn,
        ),
        typography: $general-typography,
        density: 0,
    )
);

@include mat.all-component-themes($my-theme);
@include mat.all-legacy-component-themes($my-theme);

// Frame

html,
body {
    font-size: mat.font-size($general-typography, 'body-2');
    font-weight: mat.font-weight($general-typography, 'body-2');
    font-family: mat.font-family($general-typography);
    color: var(--color-dark-grey);
    background-color: $bodyBg;
}

h1 {
    font-size: mat.font-size($general-typography, 'headline-1');
    font-weight: mat.font-weight($general-typography, 'headline-1');
    color: mat.get-color-from-palette($accent, 500);
    display: flex;
    align-items: center;

    .mat-icon {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 32px;
        margin-left: -5px;
        margin-top: -2px;
        display: grid;
        align-items: center;
    }
}
h2 {
    font-size: mat.font-size($general-typography, 'headline-2');
    font-weight: mat.font-weight($general-typography, 'headline-2');
}

b,
.bold {
    font-weight: 700;
}

.small {
    font-size: var(--font-smaller);
}

a,
a:hover,
a:visited {
    color: var(--color-dark-grey);
}

hr {
    margin: 16px 0px;
}

.ao-nav-bar {
    background: mat.get-color-from-palette($accent, 500);
    font-size: mat.font-size($general-typography, 'body-1');
    font-weight: mat.font-weight($general-typography, 'body-1');

    .nav-link {
        color: #ffffff;
        opacity: 1;
        font-weight: 700;
    }

    .nav-link.active {
        position: relative;
        color: mat.get-color-from-palette($accent, 500);
        background-color: $bodyBg;
        padding: 15px 19px !important;
    }

    .nav-link.active::before {
        content: '';
        position: absolute;
        top: 0px;
        left: -1px; //this is set to -1 and not 0. reason being that 0 will cause white stripes on certain resolutions, it seems
        border-radius: 0px 0px 5px 0px;
        background: mat.get-color-from-palette($accent, 500);
        width: 5px;
        height: 48px; //nav bar height
    }

    .nav-link.active::after {
        content: '';
        position: absolute;
        top: 0px;
        right: -1px; //this is set to -1 and not 0. reason being that 0 will cause white stripes on certain resolutions, it seems
        border-radius: 0px 0px 0px 5px;
        background: mat.get-color-from-palette($accent, 500);
        width: 5px;
        height: 48px; //nav bar height
    }

    #right-nav-part {
        .nav-link.active mat-icon {
            color: mat.get-color-from-palette($accent, 500);
        }
    }
}

.side-nav {
    background: mat.get-color-from-palette($accent, 500);
}

.nav-link.active {
    opacity: 1;
}

.checkbox-block {
    display: block !important;
}

// Material Components

.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 0.8em 0 0.8em 0;
}

.mdc-floating-label-wrapper {
    top: -1.1em !important;
    padding-top: 1.1em !important;
}

.slim-field {
    .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper,
    .mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
        margin: 0.25em 0 0 0;
    }
    .mat-mdc-text-field-wrapper {
        padding: 0;
        border-radius: 5px;
    }

    .mat-mdc-form-field-infix {
        border-top: 0.25em solid transparent;
    }
    .mdc-floating-label-wrapper {
        top: -0.6em;
        padding-top: 0.6em;
    }
    .mat-form-field-appearance-outline .mdc-floating-label {
        top: 1.1em !important;
        margin-top: 0 !important;
    }
    .mdc-floating-label {
        top: 0.4em !important;
    }
    .mat-select-arrow {
        position: relative;
        top: 5px;
    }

    .mdc-line-ripple::after,
    .mdc-line-ripple::before {
        display: none;
    }

    .mat-mdc-input-element,
    .mat-mdc-select-value {
        font-size: 1rem !important;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding: 0;
    }

    .mdc-text-field .mdc-floating-label {
        font-size: var(--font-normal);
    }
}

.slim-field.no-padding {
    .mat-mdc-text-field-wrapper {
        padding-bottom: 0em;
    }
}

.slim-field.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 0.6em 0 !important;
}

.slim-field.mat-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
    .mdc-floating-label,
.slim-field.mat-form-field-appearance-fill.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
    .mdc-floating-label,
.slim-field.mat-form-field-appearance-outline.mat-mdc-form-field-can-float
    .mat-input-server:focus
    + .mdc-floating-label-wrapper
    .mdc-floating-label,
.slim-field.mat-form-field-appearance-fill.mat-mdc-form-field-can-float
    .mat-input-server:focus
    + .mdc-floating-label-wrapper
    .mdc-floating-label {
    transform: translateY(-1em) scale(0.75) !important;
    width: 133.3333733333% !important;
}

// form fields fill

.mat-form-field-appearance-fill.white-select .mat-mdc-form-field-flex {
    background-color: white;
}

.mat-form-field-appearance-fill.wrong-type .mat-mdc-form-field-flex {
    background-color: var(--color-err-light) !important;
    color: var(--color-err);
}

.slim-field.mat-form-field-appearance-outline .mat-mdc-form-field-infix,
.slim-field.mat-form-field-appearance-fill .mat-mdc-form-field-infix {
    padding: 0.2em 0 !important;
    min-height: auto;
}

.slim-field .mat-mdc-form-field-bottom-align::before {
    height: 12px;
}

.slim-field.mat-form-field-appearance-fill .mdc-floating-label-wrapper {
    top: -0.9em;
}

.mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    border-radius: 5px !important;
    padding: 0.5em 0.5em 0 0.5em !important;
}

.mat-form-field-appearance-fill .mdc-floating-label {
    top: 0.9em;
    margin-top: 0;
}

.mat-mdc-form-field-underline {
    display: none;
}

.missing-border {
    .mat-mdc-form-field-infix {
        border-top: 0.3em solid transparent;
    }
}
.missing-error {
    .mat-mdc-form-field-wrapper {
        padding-bottom: 0;
    }
}

.mdc-button {
    text-transform: uppercase;
    border: 1px solid mat.get-color-from-palette($accent, 500);
    font-size: mat.font-size($general-typography, 'button');
    font-weight: mat.font-weight($general-typography, 'button');
    letter-spacing: mat.letter-spacing($general-typography, 'button');
    min-width: 100px !important;
    border-radius: 5px;

    .mat-icon {
        margin-top: -3px;
        height: 20px !important;
        width: 20px !important;
        line-height: 20px;
        font-size: 20px !important;
    }
}

.mat-mdc-outlined-button:not(:disabled).mat-accent {
    border-color: mat.get-color-from-palette($accent, 500);
}
.mat-mdc-outlined-button:not(:disabled).mat-primary {
    border-color: mat.get-color-from-palette($primary, 500);
}
.mat-mdc-outlined-button:not(:disabled).grey.mat-primary {
    border-color: var(--color-dark-grey);
}
.mat-mdc-outlined-button:not(:disabled).mat-warn {
    border-color: mat.get-color-from-palette($warn, 500);
}

.mat-mdc-outlined-button[disabled] {
    border-color: rgba(0, 0, 0, 0.26);
}

.mdc-button[disabled] {
    opacity: 0.7;
}

.mat-mdc-unelevated-button:not(:disabled) {
    color: var(--color-dark-grey) !important;
}

.mdc-button:focus,
.mdc-icon-button:focus {
    outline: none;
}

.mdc-button.converse {
    border: 1px solid #dcdcdc;
    background-color: transparent;
}

.mdc-button.converse[disabled] {
    opacity: 0.7;
}

.mat-mdc-unelevated-button:not(:disabled).mat-accent,
.mat-mdc-unelevated-button:not(:disabled).mat-primary {
    color: mat.get-color-from-palette($primary, default-contrast) !important;
}

.mat-mdc-dialog-surface {
    padding: 20px !important;
    min-width: 320px;

    .headline {
        position: relative;
        height: 50px;

        .mdc-icon-button {
            position: absolute;
            right: 0;
            top: -13px;
        }
        margin-bottom: 10px;
    }
    .body {
        margin-bottom: 20px;
    }
    .footer,
    .links {
        text-align: right;
    }
}

.confirm-overlay .mat-mdc-dialog-surface {
    padding: 0 !important;
}

.mat-table.header {
    th.mat-header-cell {
        padding-top: 15px;
        padding-bottom: 0px;
    }
}

.mat-table.clean {
    .mat-row:hover {
        .mat-cell {
            background-color: transparent;
        }
    }
}

.mat-table {
    th.mat-header-cell {
        font-size: mat.font-size($general-typography, 'body-2');
        font-weight: 500;
        background-color: white;
        color: var(--color-dark-grey);
    }

    th:last-child {
        padding-right: 0 !important;
    }

    .mat-row {
        cursor: pointer;
        .mat-cell {
            -moz-transition: all 0.1s ease-in;
            -o-transition: all 0.1s ease-in;
            -webkit-transition: all 0.1s ease-in;
            transition: all 0.1s ease-in;
            color: var(--color-dark-grey);
            background-color: white;
            border-bottom: 2px solid white;
        }
    }

    .edit-container {
        position: relative;
        width: 100%;
        min-height: 19px;
        button {
            position: absolute;
            right: -25px;
            top: -10px;
        }
    }
}

.mdc-tab__text-label {
    font-size: mat.font-size($general-typography, 'body-1');
    font-weight: mat.font-weight($general-typography, 'body-1');
    font-family: mat.font-family($general-typography);
    letter-spacing: normal;
}

.mat-mdc-tab-list {
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: 16px;

    .mat-mdc-tab-links {
        gap: 32px;

        .mat-mdc-tab-link {
            padding: 0px;
            min-width: auto;
            margin-right: 10px;
            opacity: 1;
        }

        .mat-mdc-tab-label-active.mat-mdc-tab-link .mdc-tab__text-label,
        .mat-mdc-tab-label-active .mdc-tab__text-label,
        .mat-mdc-tab-link.active .mdc-tab__text-label {
            color: mat.get-color-from-palette($accent, 500);
        }
        .mat-mdc-tab-label-active.mat-mdc-tab-link,
        .mat-mdc-tab-label-active,
        .mat-mdc-tab-link.active {
            border-bottom: 1px solid mat.get-color-from-palette($accent, 500);
        }
    }
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: var(--color-dark-grey);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: transparent;
}

.mdc-tab {
    padding: 0 !important;
}

.mat-mdc-tab-body {
    overflow: inherit !important;
    max-width: 100%;
}

.mat-mdc-tab-body-wrapper {
    overflow: inherit !important;
}

.mat-mdc-tab-body-content {
    overflow: inherit !important;
}

.mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    color: white !important;
}

.mat-mdc-radio-label {
    white-space: normal !important;
}

.radio-blocks .mat-mdc-radio-button {
    display: block;
}

.full-slide.mat-mdc-slide-toggle .mdc-form-field {
    justify-content: space-between;
    width: 100%;
}

.full-slide.mat-mdc-slide-toggle .mdc-form-field label {
    margin-left: 0;
}

.mat-mdc-slide-toggle .mdc-switch__icons {
    display: none;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: white;
}

.mat-button-toggle-checked {
    background-color: var(--color-prime);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 32px;
    text-transform: uppercase;
}

.wrap-box.mat-mdc-checkbox {
    white-space: normal;
    word-break: break-all;
}

.mat-mdc-checkbox {
    white-space: nowrap;
}

.mat-mdc-chip-grid {
    margin-top: 15px;
}

.tag-chips .mat-mdc-chip {
    border-radius: 5px !important;

    .mat-mdc-chip-action-label {
        color: white !important;
        text-transform: uppercase;
    }

    .mat-icon {
        margin-right: 5px;
        cursor: pointer;
    }
}

.mat-expansion-panel {
    margin-bottom: 16px !important;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15) !important;
}

.mat-expansion-panel-body {
    padding: 0 10px 16px !important;
}

.mat-expansion-panel-header {
    padding: 0 10px !important;
    font-weight: 500;
    font-size: var(--font-normal);
}

.border-panel .mat-expansion-panel-header {
    border-left: 5px solid var(--color-success-light);
}

.border-panel .active .mat-expansion-panel-header {
    border-left: 5px solid var(--color-success);
}

.border-panel .mat-expansion-panel-header.mat-expanded {
    border-bottom: 1px solid var(--color-light-grey);
    border-left: 5px solid var(--color-success);
}

.border-panel .mat-expansion-panel-body {
    border-left: 5px solid var(--color-success);
}

.mat-mdc-menu-content:not(:empty) {
    padding: 10px !important;
}

.mat-mdc-menu-content {
    font-size: var(--font-normal);
}

.no-padding .mat-mdc-menu-content:not(:empty) {
    padding: 0px !important;
}

.no-padding .mat-mdc-menu-content .action {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid var(--color-light-grey);

    &:hover {
        background-color: var(--color-success-light);
    }
}

.mat-mdc-menu-panel {
    min-height: 20px !important;
}

.mat-badge.hide-badge .mat-badge-content {
    color: transparent;
}

.mat-badge-medium .mat-badge-content {
    width: 15px;
    height: 15px;
    line-height: 15px;
    right: 0px !important;
    top: 0px !important;
}

.nav-link.mat-badge-medium .mat-badge-content {
    right: 7px !important;
    top: 5px !important;
}

.hide-badge .mat-badge-content {
    background: var(--color-warn);
}

// responsive
@media (max-width: 1279px) {
}

@media (max-width: 599px) {
}
