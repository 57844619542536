@import '../node_modules/@ah-oh/company-auth/assets/lib-styles.scss';
@import '~highlight.js/styles/github.css';
// Snazzy Info Window
.si-wrapper-top {
    margin-top: -12px !important;
}
.si-pointer-top {
    display: none;
}
.si-frame.si-content-wrapper {
    padding: 25px;
}

// company auth

.auth-btn {
    background-color: var(--color-accent) !important;
}
.auth-headline-small {
    color: var(--color-accent) !important;
}
.auth-headline-big,
.no-mail,
.auth-code {
    color: var(--color-prime) !important;
}

// Global

html {
    height: 100%;
}
body {
    width: 100%;
    position: relative;
    margin: 0 0;
    top: 0 !important;
    height: calc(100% - 70px);
}

a {
    cursor: pointer;
}

.content-container {
    width: 1640px;
    margin: 70px auto;
    min-height: calc(100vh - 200px);
    padding-bottom: 70px;

    .center {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.line {
    margin-left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: var(--color-bodyBg);
}

.hidden {
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow: hidden;
}

.ao-card {
    background-color: white;
    .headline {
        margin-bottom: 20px;
    }
    .body {
        margin-bottom: 10px;
    }
    .footer,
    .links {
        .left {
            width: 50%;
            display: inline-block;
        }
        .right {
            text-align: right;
            width: 50%;
            display: inline-block;
        }
    }
}
.ao-card.big {
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 10px;
}
.ao-card.small {
    width: 536px;
    padding: 40px;
    box-shadow: 0px 3px 6px #00000029;
}

.pdf-job-menu {
    max-width: 540px !important;
    width: 540px !important;
    max-height: 500px !important;

    .mat-mdc-menu-content {
        padding: 16px !important;
    }
}

.ao-filter-list {
    .field-container {
        width: calc(100% - 4px);
    }

    .field-name {
        height: 35px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 5px;
        background-color: white;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid var(--color-light-grey);
        font-size: 13px;

        div {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &.active {
            border-color: var(--color-prime) !important;
        }

        span {
            display: grid;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            background-color: var(--color-success-light);
            width: 22px;
            height: 22px;
            border-radius: 50%;
        }
    }

    .input {
        border-radius: 4px;
        border: none;
        outline: none;
        padding: 10px 30px 10px 5px;
        background-color: var(--color-light-grey);
        width: 100%;
        box-sizing: border-box;
    }

    .input:placeholder-shown {
        text-overflow: ellipsis;
    }
}

.filter-menu {
    margin-top: -35px;
    padding: 2px;

    .field-content {
        min-width: 150px;
    }

    .heading {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--color-dark-grey);
        cursor: pointer;
    }

    .input {
        border-radius: 4px;
        border: none;
        outline: none;
        padding: 10px 5px;
        background-color: var(--color-light-grey);
        width: 100%;
        box-sizing: border-box;
    }
    .icons {
        margin: 10px 0px;

        mat-icon {
            height: 15px;
            width: 15px;
            line-height: 15px;
            font-size: 15px;
            cursor: pointer;
        }

        span {
            display: block;
            background-color: transparent;
            padding: 5px;
            border-radius: 50%;
            display: grid;
            align-items: center;
            justify-content: center;

            &.active {
                background-color: var(--color-success-light);
            }
        }
    }

    .mat-radio-group {
        display: block;
    }
    .mat-radio-button {
        margin-bottom: 10px;
        display: block;
    }
}

.filter-menu,
app-filter-input {
    .sort-icon {
        opacity: 0.7;
        transition: transform 0.5s;
        cursor: pointer;

        &.rotate {
            transform: rotate(180deg);
        }
        &.active {
            opacity: 1;
        }
    }
}

.ao-mat-error {
    background-color: transparent;
    color: var(--color-err);
}

.spinner {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.variable-settings {
    .tab-head {
        padding: 20px;
        opacity: 0.4;
        cursor: pointer;

        &.active {
            opacity: 1;
            box-shadow: 3px -2px 15px 1px #00000014;
        }
    }

    .margin {
        margin-bottom: 16px;
    }

    .disabled {
        opacity: 0.8;
    }

    .margin-top {
        margin-top: 10px;
    }

    .white-space {
        white-space: nowrap;
    }

    .card {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin-bottom: 16px;
        padding: 16px;

        &.green {
            background-color: var(--color-success-light);
        }
    }

    .margin-left {
        margin-left: 20px;
    }

    @media (max-width: 1279px) {
        .margin-left {
            margin: 0;
        }

        .break .mat-checkbox-layout {
            white-space: break-spaces;
        }
    }
}

@media (max-width: 1689px) {
    .content-container {
        width: calc(100% - 100px);
        margin: 60px 50px;
    }
}

@media (max-width: 1279px) {
    .content-container {
        width: calc(100% - 60px);
        margin: 50px 30px;
        min-height: calc(100vh - 140px);
    }
    .ao-card.small {
        width: 410px;
    }
}

@media (max-width: 599px) {
    .ql-toolbar {
        width: 300px;
        top: -65px;
    }
    .content-container {
        width: calc(100% - 30px);
        margin: 50px 15px;
        min-height: calc(100vh - 120px);
    }
    .ao-card {
        padding: 20px;
    }
    .ao-card.small {
        width: calc(100vw - 60px);
        padding: 15px;
    }
    .ao-card.big {
        width: calc(100% - 20px);
        padding: 10px;
    }

    .pdf-job-menu {
        max-width: 300px !important;
        width: 300px !important;
    }
}

.mb {
    margin-bottom: 18px;
}

.mt {
    margin-top: 18px;
}

.mb-40 {
    margin-bottom: 40px;
}
